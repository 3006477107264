import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setAirportCollection,
  setSelectedRunway as setSelectedRunwayAction,
} from "../redux/slice";
import Map, { Marker, Popup, Source, Layer } from "react-map-gl";
import debounce from "lodash.debounce";
import "./styles/Map.css";
import { parse } from "wellknown";
// import { Oval } from "react-loader-spinner";
// import { createCirclePolygon } from "../components/CircleRadius";

function MyMap() {

  const { airportDetail, aircraftDetail, aircraftCards, map, airportCollection, disableMap, selectedRunway } =
    useSelector((state) => state.slice);
  const { longitude, latitude, zoom } = map;
  // const [circleGeoJSON, setCircleGeoJSON] = useState(null);
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [geometries, setGeometries] = useState([]);
  const [viewState, setViewState] = useState({
    latitude,
    longitude,
    zoom,
  });

  const [selectedAirport, setSelectedAirport] = useState(null);
  const [airportsLocal, setAirports] = useState(airportCollection);
  const setAirportsDispatch = (payload) => {
    dispatch(setAirportCollection(payload));
  };

  const setSelectedRunway = (payload) => {
    dispatch(setSelectedRunwayAction(payload));
  };

  const onHover = useCallback((event) => {
    const runway = event.features && event.features[0];
    if (runway) setSelectedRunway(runway.properties.id);
  }, []);

  const onHoverEnd = useCallback(() => {
    setSelectedRunway("");
  }, []);

  // const selectedRunway = (hoverInfo && hoverInfo.runwayId) || "";
  const filter = useMemo(() => ["in", "id", selectedRunway], [selectedRunway]);
  const runwayStyle = {
    type: "fill",
    paint: {
      "fill-color": "#660066",
      "fill-outline-color": "#660066",
    },
  };
  const runwayStyleHover = {
    type: "fill",
    paint: {
      "fill-color": "#ff6bff",
      "fill-outline-color": "#ff6bff",
    },
  };

  const rangeCircleColors = ['#66b3ff', '#ff6666', '#99ff99', '#ffcc99', '#c2c2f0', '#ffb3e6'];

  // ------- get airport list from current screen and debounce -----

  const getAirports = async (data) => {
    if (mapBounds && data) {
      const fetchAplist = await fetch(
        `/api/geosearch/airport/${data._sw.lng},${data._sw.lat},${data._ne.lng},${data._ne.lat}`
      );
      const airports = await fetchAplist.json();
      setAirportsDispatch(airports);
    }
  };

  const debouncedSave = useCallback(
    debounce((dataa) => getAirports(dataa), 100),
    []
  );

  // --------  this is to get map boudaries, to be used to call api to get the list of nerby airports
  const [mapBounds, setMapBounds] = useState({});
  const handleChange = () => {
    if (mapRef.current != null) {
      setMapBounds(mapRef.current.getMap().getBounds());      
    }
    debouncedSave(mapBounds);
  };

  const fetchRunway = async () => {
    if (airportDetail?.icao) {
      const fetchRunway = await fetch(`/api/airport/${airportDetail.icao}`);
      const rwlist = await fetchRunway.json();
      setGeometries(rwlist.runways);
    } else {
      setGeometries([]);
    }
  };

  // -------  get runways list only if there is icao ------
  useEffect(() => {
    fetchRunway();
  }, [airportDetail]);

  // ------ update selected (hovered) runway from props ------
  // useEffect(() => {
  // setHoverInfo({ runwayId: selectedRunway });
  // }, [selectedRunway]);

  // -------- make geojson from api response for drawing runways -------
  const gj = geometries.map(({ runway_id, runway_polygon, runway_name }) => {
    return {
      type: "Feature",
      geometry: parse(runway_polygon),
      properties: {
        name: runway_name,
        id: runway_id,
      },
    };
  });
  const gjFc = {
    type: "FeatureCollection",
    features: gj,
  };

  // -------  fly to airport function
  const flyToAirport = (longitudeParam, latitudeParam) => {
    if (longitudeParam && latitudeParam) {
      mapRef.current?.flyTo({
        center: [longitudeParam, latitudeParam],
        zoom: 12,
        speed: 4.5,
      });
    } else
      mapRef.current?.flyTo({
        center: [longitude, latitude],
        zoom: zoom,
        speed: 4.5,
      });
  };

  // --------- set zoom function
  const changeZoom = (newZoom) => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.flyTo({
        center: [viewState.longitude, viewState.latitude], // Keep the current center (longitude, latitude)
        zoom: newZoom,  // Set the new zoom level
        speed: 4.5, // Set the speed (0 is instant, 1 is normal speed, etc.)
      });
    }
  };

  // -------  fly to if props change
  useEffect(() => {
    flyToAirport();
  }, [latitude, longitude, map]);

  // -------  color the airport markers dependig on aircraft parameters (update apListGet with rwl)
  useEffect(() => {
    // const res = airportsLocal.map((item) => ({
    //   ...item,
    //   ...airportsLocal.result.find(
    //     ({ airport_id }) => airport_id === item.airport_id
    //   ),
    // }));
    // setAirports(res);

    setAirports(airportCollection); // this updates the local list of airports
  }, [airportCollection]);

  // -------  toggle map disable from props
  // useEffect(() => {
  //   setDisableMap(disableMap);
  // }, [disableMap]);

  const onAirportClick = (airport) => {
    if (airport.icao !== undefined) {
      flyToAirport(airport.longitude, airport.latitude);
    }
  };

  const onMove = (evt) => {
    setViewState(evt.viewState);
    handleChange();
  };

  // resize the map to fix the issue on phones when closing cards
  useEffect(() => {
    mapRef.current?.resize();
  }, [airportDetail, aircraftDetail]);


  const [acCLength, setAcCLength] = useState(0);

  useEffect(() => {
    // code for zooming out based on aircraft goes here; just find the largest ranging ac from the ac list and zoom out to that value
    // console.log(aircraftCards?.length)
    // obviously there's a bug here because it only changes the zoom if the length increases. BUT STILL FIRES THE ABOVE CONSOLE>LOG
    // console.log(viewState)

    const zoomValue = 1.8
    if (acCLength < aircraftCards?.length && viewState.zoom > zoomValue) {
      changeZoom(zoomValue)
    }
    setAcCLength(aircraftCards?.length)
  }, [aircraftCards?.length]); 

// console.log(aircraftCards?.length)



/////////////////////////////////////////////////


  return (
    <div className={disableMap ? "map loading" : "map"}>
      <div className={disableMap ? "loading-spinner-bcgr" : ""}>
        {/* {<div className={loadingGet ? "loading-spinner" : ""}>
          {loadingGet ? (
            <Oval
              height={120}
              width={100}
              color="white"
              ariaLabel="loading"
              strokeWidth={0}
              strokeWidthSecondary={3}
            />
          ) : null}
        </div>} */}
      </div>
      {/* DEBUGGING */}
      {/* <div
        style={{
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 9999,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '5px',
          borderRadius: '5px',
        }}
      >
        Zoom Level: {viewState.zoom}
      </div> */}
      {/* DEBUGGING */}
      <div className={"map"}>
        <Map
          {...viewState}
          ref={mapRef}
          // eslint-disable-next-line no-undef
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          attributionControl={false}
          mapStyle="mapbox://styles/avionia/ckk3rvb8i4png17tlkiiud8r9"
          // projection="globe"
          onMove={onMove}
          onRender={() => mapRef.current.resize()}
          interactiveLayerIds={["runways"]}
          onMouseEnter={onHover}
          onMouseLeave={onHoverEnd}
        >
          {/* TODO: replace this with slices values and remove airportsLocal */}
          {airportsLocal.map((airport, index) => (
            <Marker
              key={index}
              latitude={airport.latitude}
              longitude={airport.longitude}
            >
              <Link
                to={`/airport/${airport.icao}/`}
                className={
                  airport.rwl
                    ? airport.rwl > airport.max_runway_length_m
                      ? "map-airport-label red"
                      : "map-airport-label green"
                    : "map-airport-label"
                }
                onClick={() => onAirportClick(airport)}
                onMouseEnter={(e) => {
                  e.preventDefault();
                  setSelectedAirport(airport);
                }}
                onMouseLeave={() => {
                  setSelectedAirport(null);
                }}
              >
                {airport.icao}
              </Link>
            </Marker>
          ))}
          {selectedAirport ? (
            <Popup
              latitude={selectedAirport.latitude}
              longitude={selectedAirport.longitude}
              closeButton={false}
            >
              <div>
                <div className="map-tooltip-header">
                  {selectedAirport.airport_name}
                </div>
                <div className="map-tooltip-content">
                  AMSL: {selectedAirport.amsl_m} m<br></br>
                  RWY LEN: {selectedAirport.max_runway_length_m} m<br></br>
                  {selectedAirport.rwl ? (
                    <div className="rqr">RWY RQR: {selectedAirport.rwl} m</div>
                  ) : null}
                </div>
              </div>
            </Popup>
          ) : null}
          <Source type="geojson" data={gjFc}>
            <Layer {...runwayStyle} id={"runways"} />
            <Layer {...runwayStyleHover} filter={filter} />
          </Source>
          {aircraftCards?.map((aircraft, index) =>
            aircraft.rangeCircle && (
              <Source
                key={`circle-source-${aircraft.aircraft_id}`}
                id={`circle-data-${aircraft.aircraft_id}`}
                type="geojson"
                data={aircraft.rangeCircle}
              >             
                <Layer
                  id={`circle-layer-${aircraft.aircraft_id}`}
                  type="fill"
                  paint={{
                    'fill-color': rangeCircleColors[index % rangeCircleColors.length],  // Cycle through colors
                    'fill-opacity': 0.35,
                  }}
                />
                <Layer
                  id={`circle-layer-outline-${aircraft.aircraft_id}`}
                  type="line"
                  paint={{
                    'line-color': rangeCircleColors[index % rangeCircleColors.length],  // Outline color (black in this case)
                    'line-width': 1,          // Outline width
                  }}
                />
                <Layer
                  id={`text-layer-outline-${aircraft.aircraft_id}`}
                  type="symbol"
                  layout={{
                    'symbol-placement': 'line',
                    'text-font': ['Ubuntu Medium', 'Open Sans Bold'],
                    'text-size': 14,
                    'text-field': `${aircraft.icao_code}`,
                  }}
                  paint={{
                    'text-color': '#000000',            // Text color
                    'text-halo-color': '#ffffff',       // Background or outline color
                    'text-halo-width': 2               // Width of the outline
                  }}
                />
            </Source>
          ))
          }
        </Map>
      </div>
    </div>
  );
}

export default MyMap;
