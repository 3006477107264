/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
// import { Link } from "react-router-dom";
import "./styles/Sidebar.css";
import "./styles/Aircraft_detail.css";
import { 
  disableMapPush, 
  updateAircraftDetailRwl, 
  updateAircraftDetailTow,
  updateAircraftDetailPl,
  updateAircraftDetailFob,
  updateAircraftDetailRange,
} from "../redux/slice";
// import { useSelector, useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet";
import { TailSpin } from "react-loader-spinner";
// import { ReactComponent as Info } from "../data/info.svg";
// import { Oval } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";

function AircraftDetail( {aircraft} ) {
  // const { } = useSelector((state) => state.slice);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  // const [localAirports, setLocalAirports] = useState([]);
  // const [minTow, setMinTow] = useState(0);
  const [oat, setOat] = useState(15);
  const [amsl, setAmsl] = useState(0);
  //debounced values used for api call
  const [towd, setTowd] = useState(0);
  const [oatd, setOatd] = useState(15);
  const [amsld, setAmsld] = useState(0);
  const [loading, setLoading] = useState(false);

  // const [isTowExpanded, setTowExpanded] = useState(true); // dropdown for detailed tow calculation

   // function to push tow value to reducer:
   const updateTowValue = (aircraft_id, newTow) => {
    dispatch(updateAircraftDetailTow({ aircraft_id, newTow}));
  }
  // function to push rwl value to reducer:
  const updateRwlValue = (aircraft_id, newRwl) => {
    dispatch(updateAircraftDetailRwl({ aircraft_id, newRwl}));
  }
    // function to push pl value to reducer:
  const updatePlValue = (aircraft_id, newPl) => {
    dispatch(updateAircraftDetailPl({ aircraft_id, newPl}));
  }
    // function to push rwl value to reducer:
  const updateFobValue = (aircraft_id, newFob) => {
    dispatch(updateAircraftDetailFob({ aircraft_id, newFob}));
  }
    // function to push range value to reducer:
  const updateRangeValue = (aircraft_id, newRange) => {
    dispatch(updateAircraftDetailRange({ aircraft_id, newRange}));
  }

  // const [setLoadingBulk] = useState(false);
  //custom take-off calculation

  // const [calculateBulk, setCalculateBulk] = useState(false);

  // useEffect(() => {
  //   if (airportCollection) setLocalAirports(airportCollection);
  // }, [airportCollection]);

  //  const toggleTow = () => {
  //   setTowExpanded(!isTowExpanded);
  // }

  const updateDisableMap = (payload) => {
    dispatch(disableMapPush(payload));
  };

  const fetchRwl = async (aircraft_id, tow, amsl, oat) => {
    setLoading(true);
    try {
      const fetchRl = await fetch(`/api/takeoff_calculator/${aircraft_id},${tow},${amsl},${oat}`);
      const rl = await fetchRl.json();
      updateRwlValue(aircraft_id, Math.round(rl));
    } finally {
      setLoading(false);
    }
  };
  // initial set tow
  useEffect(() => {
    setTowd(aircraft.tow)
    if (aircraft && aircraft.range_formula) {
      updateRangeValue(
        aircraft.aircraft_id, 
        Math.round(
          aircraft.fob * aircraft.range_formula.fob_factor 
          + aircraft.tow * aircraft.range_formula.tow_factor 
          + aircraft.range_formula.constant
        )
      );
    }
  }, []) // this is to trigger to change of towd when the ac card renders with initial values
  // when aircraft change update tow, and check if sidebar should be active
  useEffect(() => {
    if (aircraft) {
      setSidebar(true);
      updateTowValue(aircraft.aircraft_id, (aircraft.mtow - aircraft.oew) / 2 + aircraft.oew) // set default tow value to 50% of actual load
      updateDisableMap(true);
      setTowd(aircraft.tow);
    }
    else {
      setSidebar(false)
      updateDisableMap(false)
    }
  }, [aircraft.aircraft_id]);
  // when towd, amsld or oatd change, invoke the calculator
  useEffect(() => {
    if ( towd > 0) {
      fetchRwl(aircraft?.aircraft_id, towd, amsld, oatd);
    }
  }, [towd, amsld, oatd]);
  // this handles cases when the same ac is selected from the search box
  useEffect(() => {
    if (aircraft && !aircraft.rwl && towd > 0) {
      fetchRwl(aircraft?.aircraft_id, aircraft.tow, amsld, oatd);
    }
  }, [aircraft.rwl]);

  // TODO: api request for bulk takeoff calculator with loading indicator with all the airports in the range
  // TODO: this needs fixing
  // useEffect(() => {
  //   if (
  //     !(typeof item?.aircraft_id == "undefined" || item?.aircraft_id == null) &&
  //     disableMap == false &&
  //     calculateBulk &&
  //     !(typeof aircraftCode == "undefined")
  //   ) {
  //     const airports = airports.map(({ airport_id, amsl_m }) => {
  //       return {
  //         airport_id: airport_id,
  //         amsl_m: amsl_m,
  //         aircraft_id: item?.aircraft_id,
  //         tow: Number(towd),
  //         oat: Number(oatd),
  //       };
  //     });
  //     const calcJson = {
  //       airports: airports,
  //     };
  //     // TEMPORARY DISABLED
  //     // const salji = async () => {
  //     //   setLoadingBulk(true);
  //     //   const fetchItem = await fetch(`/api/takeoff_calculator_bulk/`, {
  //     //     method: "POST",
  //     //     body: JSON.stringify(calcJson),
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   }).finally(() => {
  //     //     setLoadingBulk(false);
  //     //   });

  //     //   const item = await fetchItem.json();
  //     //   dispatch(setAirportCollection(item));
  //     // };
  //     // salji();
  //     //----------------------

  //   }
  // }, [airportCollection, towd, amsld, oatd, disableMap]);

  // define debounce functions
  const debouncedTow = useCallback(
    debounce((data) => setTowd(data), 100),
    []
  );
  const debouncedOat = useCallback(
    debounce((data) => setOatd(data), 100),
    []
  );
  const debouncedAmsl = useCallback(
    debounce((data) => setAmsld(data), 100),
    []
  );

  // custom amsl calculation

  // const handleCheckbox = () => {
  //   setCalculateBulk(!calculateBulk);
  //   updateDisableMap(!disableMap);
  // };

  function showAircraft() {
    return (
      <div className={sidebar ? "sidebar active" : "sidebar"}>
        <Helmet>
          <title>
            {aircraft.manufacturer.manufacturer_name.toUpperCase()} | {aircraft.model}-
            {aircraft.variant} - Aircraft data | Avionia
          </title>
          <meta
            name="description"
            content={
              aircraft.manufacturer.manufacturer_name.toUpperCase() +
              " | " +
              aircraft.model +
              "-" +
              aircraft.variant +
              " Aircraft data. Runway length calculator. Take-off"
            }
          />
          <meta
            name="keywords"
            content={
              aircraft.manufacturer.manufacturer_name +
              ", " +
              aircraft.model +
              "-" +
              aircraft.variant +
              ", " +
              aircraft.icao_code +
              ", " +
              aircraft.iata_code +
              ", Aircraft data. Runway length calculator. Take-off"
            }
          />
          <link
            rel="canonical"
            href={"https://www.avionia.com" + window.location.pathname}
          />
        </Helmet>

        <div className="card-header-a header-ac">
          <div
              className="close"
              onClick={() => {
                updateDisableMap(false);
                history.push("/");
              }}
            ></div>
          <h1 className="" data-toggle="tooltip" title="Aircraft model">
            {aircraft.manufacturer.manufacturer_name.toUpperCase()} {aircraft.model}-
            {aircraft.variant}
          </h1>
          <div>
            {aircraft.icao_code}|{aircraft.iata_code}
          </div>
        </div>
        <div className="card-engine" data-toggle="tooltip" title="Engine Option">
          <h2>{aircraft.engine.engine_model}</h2>
          <div>{aircraft.engine.manufacturer}</div>
        </div>
        <div className="card-thrust" data-toggle="tooltip" title="Take-off thrust per engine">
          {aircraft.engine.thrust_kn} KN
        </div>
        <div>
          <h2 className="performance-divider">Performance</h2>
          <table className="card-mass">
            <tbody>
              <tr>
                <th data-toggle="tooltip" title="Maximum take-off weight">
                  MTOW
                </th>
                <th data-toggle="tooltip" title="Maximum landing weight">
                  MLW
                </th>
                <th data-toggle="tooltip" title="Operating empty weight">
                  OEW
                </th>
              </tr>
              <tr>
                <td data-toggle="tooltip" title="Maximum take-off weight">
                  {aircraft.mtow} kg
                </td>
                <td data-toggle="tooltip" title="Maximum landing weight">
                  {aircraft.mlw} kg
                </td>
                <td data-toggle="tooltip" title="Operating empty weight">
                  {aircraft.oew} kg
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="calculator-divider">Runway length & payload-range calculator</h3>
          <div className="calculator">
            <div className="same-row" data-toggle="tooltip" title="Payload">
              <div className="label">P/L</div>
              <input
                className="ac-range"
                type="range"
                min={0}
                max={aircraft.max_payload}
                value={aircraft.pl}
                onChange={(e) => {
                  updatePlValue(aircraft.aircraft_id, parseFloat(e.target.value));
                  if (aircraft.mtow < aircraft.oew + parseFloat(e.target.value) + aircraft.fob) {
                    updateFobValue(aircraft.aircraft_id, parseFloat(aircraft.mtow - aircraft.oew - e.target.value));
                  }
                  updateTowValue(aircraft.aircraft_id, aircraft.oew + aircraft.fob + aircraft.pl);
                  if (aircraft.range_formula) {
                    updateRangeValue(
                      aircraft.aircraft_id,
                      Math.round(
                        aircraft.fob * aircraft.range_formula.fob_factor +
                        aircraft.tow * aircraft.range_formula.tow_factor +
                        aircraft.range_formula.constant
                      )
                    );
                  }
                  debouncedTow(aircraft.tow);
                }}
              />
              <input className="ac-input" type="text" value={Math.round(aircraft.pl)} readOnly/>
              <div className="units">kg {Math.round(aircraft.pl/104)} pax</div>
            </div>

            <div className="same-row" data-toggle="tooltip" title="Fuel">
              <div className="label">FOB</div>
              <input
                className="ac-range"
                type="range"
                min={0}
                max={aircraft.fuel_capacity}
                value={aircraft.fob}
                onChange={(e) => {
                  const newFobValue = Math.round(Math.max(parseFloat(e.target.value), aircraft.fuel_capacity * 0.1));
                  updateFobValue(aircraft.aircraft_id, newFobValue);
                  if (aircraft.mtow < aircraft.oew + newFobValue + aircraft.pl) {
                    updatePlValue(aircraft.aircraft_id, parseFloat(aircraft.mtow - aircraft.oew - newFobValue));
                  }
                  updateTowValue(aircraft.aircraft_id, aircraft.oew + aircraft.fob + aircraft.pl);
                  if (aircraft.range_formula) {
                    updateRangeValue(
                      aircraft.aircraft_id,
                      Math.round(
                        aircraft.fob * aircraft.range_formula.fob_factor +
                        aircraft.tow * aircraft.range_formula.tow_factor +
                        aircraft.range_formula.constant
                      )
                    );
                  }
                  debouncedTow(aircraft.tow);
                }}
              />
              <input className="ac-input" type="text" value={Math.round(aircraft.fob)} readOnly/>
              <div className="units">kg</div>
            </div>

            <div className="same-row" data-toggle="tooltip" title="Take-off outside air temperature">
              <div className="label">OAT</div>
              <input
                className="ac-range"
                type="range"
                min="-20"
                max="50"
                value={oat}
                onChange={(e) => {
                  setOat(e.target.value);
                  debouncedOat(e.target.value);
                }}
              />
              <input className="ac-input" type="text" value={oat} onChange={(e) => {
                if (e.target.value > -20 && e.target.value < 50) {
                  setOat(e.target.value);
                  debouncedOat(e.target.value);
                }
              }}/>
              <div className="units">°C</div>
            </div>

            <div className="same-row" data-toggle="tooltip" title="Take-off airport elevation">
              <div className="label">AMSL</div>
              <input
                className="ac-range"
                type="range"
                min="-100"
                max="4500"
                value={amsl}
                onChange={(e) => {
                  setAmsl(e.target.value);
                  debouncedAmsl(e.target.value);
                }}
              />
              <input className="ac-input" type="text" value={amsl} onChange={(e) => {
                if (e.target.value > -100 && e.target.value < 4500) {
                  setAmsl(e.target.value);
                  debouncedAmsl(e.target.value);
                }
              }}/>
              <div className="units">m</div>
            </div>
          </div>
          <div className="results">
              <div className="runway-length">
                <div className="label">Total TOW: {" "}</div>
                <div className="result">
                      {aircraft.tow != null ? Math.round(aircraft.tow) : "-"}                      
                </div>
                <div className="units">kg</div>
              </div>
              <div className="runway-length">
              <div className="label">Required runway length:{" "}</div>
              <div className="result">
                {!loading ? (
                  aircraft.rwl ? aircraft.rwl : "-"
                ) : (
                  <TailSpin
                    color="#29417a"
                    height="20"
                    width="20"
                    visible={true}
                  />
                )}                
                </div>
                <div className="units">m</div>
              </div>
              <div className="runway-length">
                <div className="label">Range: {" "}</div>
                <div className="result">
                      {aircraft.range != null ? aircraft.range : "-"}                      
                </div>
                <div className="units">NM</div>
              </div>
            {/* TODO */}
            {/* <div className="ap-list">
              {localAirports.length ? (
                localAirports.map((airport, index) => (
                  <li className="ac" key={index}>
                    <Link
                      // onClick={() => addAircraft(ac.aircraft_url_code)}
                      to={
                        !window.location.pathname.includes(airport.icao)
                          ? `${window.location.pathname},${airport.icao}`
                          : `${window.location.pathname}`
                      }
                    >
                      <div>
                        {airport.icao} {airport.name}
                      </div>
                    </Link>
                  </li>
                ))
              ) : (
                <Oval />
              )}
            </div> */}
            <ReactTooltip place="top" type="dark" effect="float" />
          </div>
          </div>
        </div>
      // </div>
    );
  }

  if (aircraft?.aircraft_id) {
    return showAircraft();
  }

  return null;
}

export default AircraftDetail;
