/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
// import React from "react";
import { useHistory, Link } from "react-router-dom";
import "./styles/Sidebar_r.css";
import "./styles/Aircraft_card.css";
import { useSelector, useDispatch } from "react-redux";
import {
  updateAircraftTow,
  updateAircraftRwl,
  updateAircraftPl,
  updateAircraftFob,
  updateAircraftRange,
  updateAircraftRangeCircle,
} from "../redux/slice";
import debounce from "lodash.debounce";
import { TailSpin } from "react-loader-spinner";
import { createCirclePolygon } from "../components/CircleRadius";

function AircraftCard({ aircraft }) {
  const [cardActive, setCardActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const minTow = 50000
  // const [minTow, setMinTow] = useState(0);
  // setMinTow(50000)

  // debounced values used for api call
  const [towd, setTowd] = useState(0);

  // define debounce functions
  const debouncedTow = useCallback(
    debounce((data) => setTowd(data), 200),
    []
  );

  const { aircraftCards, airportDetail } = useSelector((state) => state.slice); // Get the existing aircraft codes from the Redux state


  useEffect(() => {
    debouncedTow(aircraft.tow)
  }, []) // this is to trigger the change of towd when the ac card renders with initial values


  useEffect(() => {
    if (aircraft && aircraft.range_formula) {
      updateRangeValue(
        aircraft.aircraft_id, 
        Math.round(
          aircraft.fob * aircraft.range_formula.fob_factor 
          + aircraft.tow * aircraft.range_formula.tow_factor 
          + aircraft.range_formula.constant
        )
      );
    }
  }, []); // this is to trigger the change of range when the ac card renders with initial values



// update runway length value:
  useEffect(() => {
    if (towd) {      
      setLoading(true);
      const fetchItem = async () => {
        if (!isNaN(towd) && towd > 0 ) {
          const fetchItem = await fetch(`/api/takeoff_calculator/${aircraft.aircraft_id},${towd},${airportDetail.amsl_m},15`).finally(() => {
            setLoading(false);
          });
          const rl = await fetchItem.json();
          updateRwlValue(aircraft.aircraft_id, Math.round(rl))
        }
      };
      fetchItem();
    }
  }, [towd]);



  

  // function to push tow value to reducer:
  const updateTowValue = (aircraft_id, newTow) => {
    dispatch(updateAircraftTow({ aircraft_id, newTow}));
  }
  // function to push rwl value to reducer:
  const updateRwlValue = (aircraft_id, newRwl) => {
    dispatch(updateAircraftRwl({ aircraft_id, newRwl}));
  }
  // function to push pl value to reducer:
  const updatePlValue = (aircraft_id, newPl) => {
    dispatch(updateAircraftPl({ aircraft_id, newPl}));
  }
  // function to push fob value to reducer:
  const updateFobValue = (aircraft_id, newFob) => {
    dispatch(updateAircraftFob({ aircraft_id, newFob}));
  }
  // function to push range value to reducer + limit the range not to be below 0NM
  const updateRangeValue = (aircraft_id, newRange) => {
    const limitedRange = Math.max(newRange, 0);
    dispatch(updateAircraftRange({ aircraft_id, newRange: limitedRange}));
  }
  // function to push range circle to reducer
  const updateRangeCircle = (aircraft_id, newRangeCircle) => {
    dispatch(updateAircraftRangeCircle({ aircraft_id, newRangeCircle}));
  }


  // update range circle

  useEffect(() => {
    if (aircraft.range) {
    updateRangeCircle(aircraft.aircraft_id, createCirclePolygon([airportDetail.longitude, airportDetail.latitude], aircraft.range));
    // console.log(aircraft.rangeCircle)
    }
  }, [aircraft.range])



  //function to remove the aircraft from url
  const removeAircraftFromURL = (aircraftCodeToRemove) => {
      // Filter out the specific aircraft object based on url_code
    const updatedAircraftCards = aircraftCards.filter(
    (aircraft) => aircraft.url_code !== aircraftCodeToRemove
    );
    const basePath = window.location.pathname.split("/").slice(0, 3).join("/"); // Get the base URL without the aircraft codes
    // Extract the url_code values from the updated aircraftCards array
    const updatedAcs = updatedAircraftCards.map((aircraft) => aircraft.url_code);
    const newPath = updatedAcs.join("&"); // Join the updated aircraft codes with "&" separator
    history.push(`${basePath}/${newPath}`); // Update the URL without reloading the page
  };

  useEffect(() => {
    if (aircraft?.aircraft_id) {
      setCardActive(true);
    }
    else setCardActive(false);
  }, [aircraft.aircraft_id]);


  function showCard() {
    return (
      <div className={`${cardActive ? "ac-card active" : "ac-card"} ${
        aircraft.rwl < airportDetail.max_runway_length_m
          ? "green"
          : aircraft.rwl > airportDetail.max_runway_length_m
          ? "red"
          : ""
      }`}>
        <div
          className="close"
          onClick={() => {
            removeAircraftFromURL(aircraft.url_code);
          }}
        ></div>
        <Link className="ac-model-button" to={`/aircraft/${aircraft.url_code}/`}>
          <div>{aircraft.manufacturer.manufacturer_name.toUpperCase()}{" "}
            {aircraft.model}-{aircraft.variant}  |  {aircraft.icao_code}</div>
          <div className="engine">
            {aircraft.engine.engine_model}
          </div>
        </Link>
        <div className="calculator-card">
          <div className="advanced-sliders">
            <div className="card-same-row" data-toggle="tooltip" title="Payload">
              P/L<input
                className="ac-range-card"
                type="range"
                min={0}
                max={aircraft.max_payload}
                value={aircraft.pl}
                onChange={(e) => {
                  {
                    updatePlValue(aircraft.aircraft_id, parseFloat(e.target.value));
                  }
                if (aircraft.mtow < aircraft.oew + parseFloat(e.target.value) + aircraft.fob)
                  { 
                    updateFobValue(aircraft.aircraft_id, parseFloat(aircraft.mtow-aircraft.oew-e.target.value))
                  }
                updateTowValue(aircraft.aircraft_id, aircraft.oew + aircraft.fob + aircraft.pl)
                if (aircraft.range_formula && aircraft.range_formula !== null) {
                updateRangeValue(aircraft.aircraft_id, Math.round(aircraft.fob*aircraft.range_formula.fob_factor 
                  + aircraft.tow*aircraft.range_formula.tow_factor 
                  + aircraft.range_formula.constant));
                // console.log(aircraft)
                }
                debouncedTow(aircraft.tow)
                   
                }}
              ></input>
              <input
                className="ac-input"
                type="text"
                value={Math.round(aircraft.pl)}
                // onChange={(e) => {
                //   if (e.target.value > aircraft.oew && e.target.value < aircraft.mtow) {
                //     updateTowValue(aircraft.aircraft_id, parseFloat(e.target.value))
                //     // to lower the rate of calls to the api
                //     debouncedTow(parseFloat(e.target.value));
                //   }
                // }}
              ></input>
              <div className="units">kg</div>
            </div>
            <div className="card-same-row" data-toggle="tooltip" title="Fuel">
              FOB<input
                className="ac-range-card"
                type="range"
                min={0}
                max={aircraft.fuel_capacity}
                value={aircraft.fob}
                onChange={(e) => {
                  const newFobValue = Math.round(Math.max(parseFloat(e.target.value), aircraft.fuel_capacity * 0.1));
                  {                    
                    updateFobValue(aircraft.aircraft_id, parseFloat(newFobValue))
                  }
                if (aircraft.mtow < aircraft.oew + newFobValue + aircraft.pl)
                  {
                    updatePlValue(aircraft.aircraft_id, parseFloat(aircraft.mtow-aircraft.oew-newFobValue))
                  }
                  updateTowValue(aircraft.aircraft_id, aircraft.oew + aircraft.fob + aircraft.pl)
                  if (aircraft.range_formula && aircraft.range_formula !== null) {
                    updateRangeValue(aircraft.aircraft_id, Math.round(aircraft.fob*aircraft.range_formula.fob_factor 
                      + aircraft.tow*aircraft.range_formula.tow_factor 
                      + aircraft.range_formula.constant))
                    }
                  debouncedTow(aircraft.tow)
                }}
              ></input>
              <input
                className="ac-input"
                type="text"
                value={Math.round(aircraft.fob)}
                // onChange={(e) => {
                //   if (e.target.value > 0) {
                //     updatePlValue(aircraft.aircraft_id, parseFloat(aircraft.mtow-aircraft.oew-aircraft.fob))
                //   }
                // }}
              ></input>
              <div className="units">kg</div>
            </div>
          </div>
          <div className="rwl">
            Total TOW:{" "}
            <div className="value">
              {!loading ? (
                aircraft.tow ? Math.round(aircraft.tow) + " kg" : "-"
              ) : (
                <TailSpin
                  color="#29417a"
                  height="20"
                  width="20"
                  visible={true}
                />
              )}
            </div>
          </div>
          <div className="rwl">
            Required runway length: {" "}
            <div className="value">
              {!loading ? (
                aircraft.rwl ? aircraft.rwl + " m" : "-"
              ) : (
                <TailSpin
                  color="#29417a"
                  height="20"
                  width="20"
                  visible={true}
                />
              )}
            </div>
          </div>
          <div className="rwl">
            Range:{" "}
            <div className="value">
              {!loading ? (
                aircraft.range != null ? aircraft.range + " NM" : "-"
              ) : (
                <TailSpin
                  color="#29417a"
                  height="20"
                  width="20"
                  visible={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }  

  if (aircraft?.aircraft_id) {
    return showCard();
  }

  return null;
}

export default AircraftCard;